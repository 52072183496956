import { compactToFMap, typesOfFacilities } from "./sharedData"

export const getCompactTypeOfFacility = (value) => {
  if (!typesOfFacilities.includes(value)) {
    return "Business"
  } else if (value === "Factory/Manufacturer") {
    return "Factory"
  } else if (value === "Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen" || compactToFMap?.[value]) {
    return "Business"
  }
  return value
}

export const mobileNumWithCountryCode = (mobileNo) => {
  return mobileNo.startsWith("+91") ? mobileNo : `+91${mobileNo}`
}

export function trackMixpanelEvent (mixpanelInstance, eventName, pageName = '') {
  if (!mixpanelInstance || !eventName) return

  if (pageName) eventName += ` (${pageName})`
  mixpanelInstance.track(eventName)
}