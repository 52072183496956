import React from "react"
import styled from "styled-components"
import { Text } from "../../core/commonExports"

import TypeOfFacilities from "../../../assets/images/campaigns/total-stats/type-of-facilities.svg"
import Businesses from "../../../assets/images/campaigns/total-stats/businesses.svg"
import Claims from "../../../assets/images/campaigns/total-stats/claims.svg"
import { getCompactTypeOfFacility } from "../../LeadFormSteps/sharedUtils"
import { typesOfFacilities } from "../../LeadFormSteps/sharedData"

const tofInsured = {
	'Shop': '2700+',
	'Godown/Warehouse': '375+',
	'Office': '290+',
	'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen': '880+',
	'Factory/Manufacturer': '650+',
	'Motor vehicle showroom': '150+',
	'Motor vehicle garage': '120+',
}

const getPluralTypeOfFaciility = (typeOfFacility) => {
  const compactTypeOfFacility = getCompactTypeOfFacility(typeOfFacility)
  if (compactTypeOfFacility === "Business") {
    return "Businesses"
  } else if (compactTypeOfFacility === "Factory") {
    return "Factories"
  } else if (compactTypeOfFacility === "Godown/Warehouse") {
    return "Godowns/Warehouses"
  } 
  return `${compactTypeOfFacility}s`
}

const getStats = (typeOfFacility) => {
  let staticStats = [
    {
      icon: <Businesses className="stat-icon" />,
      stat: "5000+",
      text: "Businesses are Verak-insured"
    },
    {
      icon: <Claims className="stat-icon" />,
      stat: "170+",
      text: "Claims approved by insurers"
    },
  ]

  if (typesOfFacilities.includes(typeOfFacility)) {
    const pluralTypeOfFaciility = getPluralTypeOfFaciility(typeOfFacility)
    
    return [
      {
        icon: <TypeOfFacilities className="stat-icon" />,
        stat: tofInsured[typeOfFacility],
        text: `${pluralTypeOfFaciility} are insured with us`
      },
      ...staticStats
    ]
  }
  return staticStats
}

const TotalStats = ({typeOfFacility = ""}) => {
  const stats = getStats(typeOfFacility)

  return (
    <>
      <StatsContainer>
        {stats.map(item => (
          <StatContainer>
            {item.icon}
            <div style={{display: "flex", flexDirection: "column", gap: "0.5rem", justifyContent: "center"}}>
              <Text fontSize="32px" mfontSize="16px" lineHeight="16px" mlineHeight="14px" fontWeight="bold" color="#2A2B7F" style={{textAlign: "left"}} desktopStyles={{margin: "0", marginBottom: "1rem"}} mobileStyles={{margin: "0"}}>
                {item.stat}
              </Text>
              <Text fontSize="16px" mfontSize="12px" lineHeight="16px" mlineHeight="14px" fontWeight="500" color="rgb(153, 153, 153)" style={{textAlign: "left", margin: "0"}}>
                {item.text}
              </Text>
            </div>
          </StatContainer>
        ))}
      </StatsContainer>
    </>
  )
}

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media screen and (max-width: 768px) {
    gap: 8px;
    align-items: center;
  }
`

const StatContainer = styled.div`
  padding: 30px;
  display: flex;
  gap: 24px;
  border: 1px solid #A96AF7;
  border-radius: 10px;
  width: 396px;
  height: 140px; 

  .stat-icon {
    width: 80px;
    height: 80px;
  }

  @media screen and (max-width: 768px) {
    padding: 4px;
    gap: 10px;
    width: min(261px, 100%);
    height: 56px;

    .stat-icon {
      width: 45px;
      height: 45px;
    }
  }
`

export default TotalStats