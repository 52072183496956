export const typesOfFacilities = [
  'Shop',
  'Godown/Warehouse',
  'Office',
  'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen',
  'Factory/Manufacturer',
  'Motor vehicle showroom',
  'Motor vehicle garage',
  'Hospitals, Clinics, Diagnostic centres',
  'Petrol, Diesel Pump',
  'Schools, colleges, coaching centers, skill training institutes and other educational institutions',
  'Laundries / Dry Cleaning',
  'Indoor stadiums, Health Club, Gymnasium and Swimming pool'
]

export const hideNOBforBusinessTypes = [
  'Motor vehicle showroom',
  'Motor vehicle garage',
  'Restaurant/Cafe/Hotel/Resort/Canteen/Cloud Kitchen',
  'Hospitals, Clinics, Diagnostic centres',
  'Schools, colleges, coaching centers, skill training institutes and other educational institutions',
  'Laundries / Dry Cleaning',
  'Indoor stadiums, Health Club, Gymnasium and Swimming pool',
]

export const shopsNOB = [
  'Retailing of FMCG/Grocery products',
  'Retailing of medical/pharmaceutical products',
  'Retailing of garments',
  'Retailing of electronic/electrical products',
  'Retailing of hardware products',
  'Retailing of jewellery',
  'Retailing of plastic products',
  'Retailing of chemical products/fertilizers',
  'Retailing of paints',
  'Retailing of Furniture',
  'Retailing of footwear',
  'Retailing of stationery products',
  'Beauty Salon, Beauty Parlour, Cosmetic shop',
]

export const godownNOB = [
  'Storage of FMCG/Grocery products',
  'Storage of garments',
  'Storage of furniture',
  'Storage of electronic/electrical products',
  'Storage of hardware products',
]

export const factoryNOB = [
  'Garments', 
  'Electronic/Electrical Products', 
  'Furniture/Wooden Materials',
  'Spices',
  'Plastic Materials',
  'Steel/Aluminium/Metal (Workshop)',
  'Rubber Products',
  'Paper Products',
  'Food Items',
  'Oil',
  'Making of sweets/confectionery items',
]

export const officeNOB = [
  'Real estate',
  'Consultancy',
  'Digital Marketing',
  'Travel Agency',
  'IT Services (Software)',
  'Legal Services',
  'Financial Service (Loan, mutual funds etc)',
  'Interior Designing Services',
]

export const gasStationNOB = [
  'With CNG',
  'Without CNG',
]

export const allNOBs = [
  ...shopsNOB,
  ...godownNOB,
  ...factoryNOB,
  ...officeNOB,
  ...gasStationNOB,
  "Restaurant" // this NoB is sent as constant for Restaurant/Cafe/...
]

export const compactToFMap = {
  "Schools, colleges, coaching centers, skill training institutes and other educational institutions": "Schools, colleges, coaching centers",
  "Indoor stadiums, Health Club, Gymnasium and Swimming pool": "Indoor stadiums, Gyms, Swimming pools"
}

export const compactShopsNoBMap = {
  'Retailing of FMCG/Grocery products': "FMCG/Grocery products",
  'Retailing of medical/pharmaceutical products': "Medical/pharmaceutical products",
  'Retailing of garments': "Garments",
  'Retailing of electronic/electrical products': "Electronic/electrical products",
  'Retailing of hardware products': "Hardware products",
  'Retailing of jewellery': "Jewellery",
  'Retailing of plastic products': "Plastic products",
  'Retailing of chemical products/fertilizers': "Chemical products/fertilizers",
  'Retailing of paints': "Paints",
  'Retailing of Furniture': "Furniture",
  'Retailing of footwear': "Footwear",
  'Retailing of stationery products': "Stationery products",
  'Beauty Salon, Beauty Parlour, Cosmetic shop': "Beauty Salon, Beauty Parlour, Cosmetic shop"
}

export const compactGodownNoBMap = {
  'Storage of FMCG/Grocery products': "FMCG/Grocery products",
  'Storage of garments': "Garments",
  'Storage of furniture': "Furniture",
  'Storage of electronic/electrical products': "Electronic/electrical products",
  'Storage of hardware products': "Hardware products",
}

export const collectGoodsDataForToFs = [
  'Shop', 
  'Petrol, Diesel Pump'
]

export const materialsList = [
  'Asphalt',
  'Bamboo',
  'Cement', 
  'Canvas',
  'Plastic Cloth',
  'Tarpaulin',
  'Tin', 
  'Wooden Planks / Thatched Leaves and/or Grass/Hay of any kind',
]

export const roofMaterialsList = [
  'Bricks/Cement/Concrete',
  'Tarpaulin',
  'Bamboo',
  'Tin',
  // 'Canvas',
  'Plastic'
]

export const wallMaterialsList = roofMaterialsList

export const fieldWiseProspectingStates = {
  "typeOfFacility": "PARTIAL_PROSPECT_1A_WEB",
  "natureOfBusiness": "PARTIAL_PROSPECT_1B_WEB",
  "wallMaterial": "PARTIAL_PROSPECT_2A_WEB",
  "roofMaterial": "PARTIAL_PROSPECT_2B_WEB",
  "goodsData": "PARTIAL_PROSPECT_3A_WEB",
  "rawMaterials": "PARTIAL_PROSPECT_3A_WEB",
  "finishedGoods": "PARTIAL_PROSPECT_3B_WEB",
  "goodsSiValue": "PARTIAL_PROSPECT_3C_WEB",
  "rawMaterialsSiValue": "PARTIAL_PROSPECT_3C_WEB",
  "finishedGoodsSiValue": "PARTIAL_PROSPECT_3D_WEB",
  "ownership": "PARTIAL_PROSPECT_4A_WEB",
  "sumInsuredBreakdown": "PARTIAL_PROSPECT_4B_WEB",
  "pincode": "PARTIAL_PROSPECT_5A_WEB",
  "floor": "PARTIAL_PROSPECT_5B_WEB",
  "address": "PARTIAL_PROSPECT_5C_WEB",
  "GST": "PARTIAL_PROSPECT_6_WEB",
  "prospectDataCollected": "PROSPECT_DONE_WEB",
  "callSchedule": "PROSPECT_DONE_WEB",
  "selfServe": "SELF_SERVE_DONE_WEB"
}

export const floorValues = [
  {label: "Ground Floor ", value: "0"},
  {label: "1st floor", value: "1"},
  {label: "2nd floor", value: "2"},
  {label: "3rd floor", value: "3"},
  {label: "4th floor", value: "4"},
  {label: "5th floor", value: "5"},
  {label: "Whole Building", value: "Whole Building"},
  {label: "Multiple Floors", value: "Multiple Floors"},
  {label: "Basement (below ground floor)", value: "-1"},
]
